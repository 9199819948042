import { Grid } from "@mui/material";
import nonstopLogo from '../assets/imgs/nonstopLogo.png'


export default function GestaoExclusividade() {
    return (
        <>
            {/* ===== PibeImob ===== */}
            <Grid container
                xs={8}
                md={8}
                lg={6}
                paddingTop={1}
            >
                <div className="imGrid" >
                    <div className="imCard">
                        <img className="imLogo" src={nonstopLogo} />
                        <div className="dataLandInfo">
                            <h1 className="imH1">
                                NonStop
                            </h1>
                            <p className="imP">
                            A Nonstop é uma plataforma integrada feita para agentes imobiliários, incorporadoras, iBuyers, fundos de investimento imobiliário e gestoras de patrimônio.
                            Nós simplificamos o processo de vendas e gestão de imóveis, fornecendo informações precisas e atualizadas em tempo real, e facilitando parcerias seguras.


                            </p>
                        </div>
                    </div>
                </div>
                <div className="gridButton">
                    <div className="imGrid">
                        <Grid item xs={10}
                            style={{
                                marginTop: '1rem',
                                marginLeft: '3.5rem'
                            }}>
                            <a target='_blank' href="https://drive.google.com/file/d/1YERPFK7PsUEghP0QI2TdgvFVEQtKsbch/view">
                                <button className='btn btnSecondary'
                                >
                                    Termo de Parceria
                                </button>
                            </a>
                        </Grid>

                        <Grid item xs={2}
                            style={{
                                marginTop: '1rem'
                            }}>
                            <a href="https://www.usenonstop.com/auth/signin" target='_blank'>
                                <button className='btn btnPrimary'
                                >
                                    Acessar
                                </button>
                            </a>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <div style={{
                width: '4rem'
            }}>
            </div>

        </>
    )
}